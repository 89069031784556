
<template>
  <div class="budget-bontract-settle-container">
    <div class="budget-bontract-settle-head">
      <a-input class="search-item" placeholder="请输入公司名称或账号名称" style="width: 260px" allowClear v-model.trim="query.name" />
      <a-button type="primary" class="search-btn" @click="handleSearch">
        查询
      </a-button>
    </div>
    <div class="budget-bontract-settle-main">
      <a-spin :spinning="isLoading">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="partnerName" label="部署平台" width="160" fixed> </el-table-column>
          <el-table-column prop="registerTime" label="注册时间" width="180"> </el-table-column>
          <el-table-column prop="company" label="公司名称" width="180"> </el-table-column>
          <el-table-column prop="userName" label="账号名称" width="180"> </el-table-column>
          <el-table-column prop="email" label="登录邮箱" width="180"> </el-table-column>
          <el-table-column prop="contact" label="联系人" width="120"> </el-table-column>
          <el-table-column prop="mobile" label="联系方式" width="150"> </el-table-column>
          <el-table-column prop="dspNum" label="授权预算个数" width="120">
            <template slot-scope="{row}">
              <div>{{ numFormat(row.dspNum || 0, 0) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="sts" label="合同状态" width="120">
            <template slot-scope="{row}">
              <a-switch size="small" @change="handleChangeSts($event, row)" :checked=" +row.sts === 1" />
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <div class="action-slot" slot-scope="{row}">
              <span class="btn" @click="handleEditBudget(row)">编辑</span>
            </div>
          </el-table-column>
          <template slot="empty">
            <m-empty style="height: 341px" imgHeight="176px">
              <template #description>暂时没有任何数据</template>
            </m-empty>
          </template>
        </el-table>
      </a-spin>
      <a-pagination
        class="pagination"
        v-if="tableData.length > 0"
        show-size-changer
        :current="query.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
        :pageSize="query.limit"
      />
      <m-empty v-else style="height: 341px" imgHeight="176px">
        <template #description>暂时没有任何数据 </template>
      </m-empty>
    </div>
    <AddbudgetsettleModal @handleChange="handleSearch" @modalCancel="addBudgetVisible = false" :budgetInfo="budgetInfo" :isAdd="isAdd" :visible="addBudgetVisible"/>
  </div>
</template>

<script>
import AddbudgetsettleModal from '../components/AddbudgetsettleModal'
import { numFormat } from '@/utils/dealNumber'
import {
  contractPage,
  updateContractSts } from '@/apiForManage/mediaAccount'
import mixDate from '@/mixins/initDate'

export default {
  mixins: [mixDate],
  components: { AddbudgetsettleModal },
  data () {
    return {
      query: {
        page: 1,
        limit: 10
      },
      total: 0,
      addBudgetVisible: false,
      isAdd: true,
      budgetInfo: {},
      tableData: [],
      isLoading: false,
      sourceInfo: {},
      configPlaceVisible: false
    }
  },
  created () {
    this.getTableList()
  },
  methods: {
    numFormat,
    handleEditBudget (item) {
      this.isAdd = false
      this.budgetInfo = JSON.parse(JSON.stringify(item))
      this.addBudgetVisible = true
    },
    handleSearch () {
      this.query.page = 1
      this.getTableList()
    },
    handleChangeSts (e, item) {
      updateContractSts({
        sts: e ? 1 : 0,
        id: item.id,
        contractId: item.contractId
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success('修改成功')
          this.getTableList()
        }
      })
    },
    handleAddBudget () {
      this.isAdd = true
      this.addBudgetVisible = true
    },
    // 修改父表page
    handleChangePage (page) {
      this.query.page = page
      this.getTableList()
    },
    // 修改父表limit
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getTableList()
    },
    async getTableList () {
      this.isLoading = true
      const { data = {} } = await contractPage(this.query)
      const { total = 0, items = [] } = data
      this.total = total
      this.tableData = items
      this.isLoading = false
    }
  }
}
</script>

<style lang="less" scoped>
.budget-bontract-settle-container {
  margin: 0 @mediumMargin @mediumMargin @mediumMargin;
  .budget-bontract-settle-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: @mediumMargin;
    .search-item{
      width: 220px;
    }
    .search-btn{
      width: 88px;
    }
  }
  .budget-bontract-settle-main {
    ::v-deep {
      .el-table__empty-block {
        border-bottom: 1px solid #EBEEF5;
      }
    }
    background-color: #fff;
    border-radius: @mediumRadius;
    padding: 0 @smallMargin;
    border: 1px solid @modelBorderColor;
    box-shadow: @modelShadow;
    padding-top: calc(@smallMargin - @thPadding);
    .pagination{
      padding: @smallMargin 0;
    }
  }
}
.action-slot {
      user-select: none;
      .btn {
        color: @primary-color;
        cursor: pointer;
      }
      .line {
        color: #ccc;
      }
      span {
        margin-right: 5px;
      }
    }
</style>
